import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { CollectorService } from "services/collector-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class collectorDetail {
  private collector: Models.Collector;

  constructor(
    private collectorService: CollectorService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.collectorService
      .get(params.Id)
      .then(collector => {
        this.collector = collector;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateCollector() {
    this.collectorService
      .put(this.collector, this.collector.Id)
      .then(res => {
        this.eventAggregator.publish("collectorListReset", 1);
        this.toastService.showSuccess("collector.updated");
        this.router.navigateToRoute("collector-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteCollector() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.collectorService
          .delete(this.collector.Id)
          .then(res => {
            this.eventAggregator.publish("collectorListReset", 1);
            this.toastService.showSuccess("collector.deleted");
            this.router.navigateToRoute("collector-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      ); 
  }
  gotoParentView(){
    this.eventAggregator.publish("collectorListReset", 1);
    this.router.navigateToRoute("collector-list");   
  }
}
