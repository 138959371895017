import { EventAggregator } from 'aurelia-event-aggregator';
import {
  autoinject,
  bindable,
  BindingEngine,
  observable,
} from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { Models } from 'models/core';
import {
  CustomerService,
  CustomerServiceFilter,
} from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { IndustryService } from 'services/industry-service';
import { ListSettingsService } from 'services/list-settings-service';

@autoinject
export class CustomerListTable {
  private CUSTOMER_FILTERS_KEY = 'CUSTOMER_FILTERS_KEY';

  private tableData: Array<Models.Customer>;
  private totalItems;
  private ready: boolean = false;
  private searchInputHasFocus: boolean = false;

  @bindable private params: any;
  @bindable private pageSize = 25;
  @bindable private context: string = '';
  @bindable private currentPage: number = 1;

  @bindable userIndustryId: number | undefined;

  @observable({ changeHandler: 'onIndustryChanged' }) industryId: number;
  private onIndustryChanged(newId?: string, oldId?: string) {
    if (newId === oldId) return;
    this.filters.industryId = newId;
    this.currentPage = 1;
    this.doSaveStateAndUpdateCustomers();
  }

  private tableViewType: number = 1;
  private tableViewVerticalLines: boolean = false;
  private tableMargin: number = 2;

  private filters: CustomerServiceFilter = {
    searchText: null,
    orderBy: 'Name',
    top: this.pageSize,
    skip: null,
    industryId: null,
    count: true,
  };

  constructor(
    private bindingEngine: BindingEngine,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private listSettingsService: ListSettingsService,
    private industryService: IndustryService
  ) {}

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private attached() {
    this.filters.industryId = this.userIndustryId;
    this.industryId = this.userIndustryId;
    this.listSettingsService.getDefaultPageSize().then((defaultPageSize) => {
      this.pageSize = defaultPageSize;

      // Get current page from query string
      if (this.params.currentPage > 1) {
        this.currentPage = this.params.currentPage;
      }

      // Get current searchText from query string
      if (this.params.searchText) {
        this.filters.searchText = this.params.searchText;
      }

      this.bindingEngine
        .propertyObserver(this.filters, 'searchText')
        .subscribe((newValue, oldValue) => {
          this.filterValueChanged('searchText', newValue, oldValue);
        });

      // list settings
      this.getCustomers();
    });
  }

  private async activate() {
    await this.industryService.getAllCached();
  }

  private currentPageChanged(newValue, oldValue) {
    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    const stateObj = this.updateStateObject('currentPage', newValue);
    this.replaceState(stateObj);

    this.filters.skip = (newValue - 1) * this.filters.top;
    this.getCustomers();
  }

  private filterValueChanged(key, newValue, oldValue) {
    if (newValue === oldValue) {
      return;
    }
    // Always go back to first page when changing filter values
    this.currentPage = 1;
    const stateObj = this.updateStateObject(key, newValue);
    this.replaceState(stateObj);
    this.getCustomers();
  }

  private updateStateObject(keyToUpdate, newValue) {
    const stateObj = {
      currentPage: this.currentPage,
      searchText: this.filters.searchText,
    };
    stateObj[keyToUpdate] = newValue;
    return stateObj;
  }

  private buildQueryString(obj) {
    const arr = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        // dont include keys where value is null
        if (obj[p]) {
          arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return arr.join('&');
  }

  private replaceState(stateObj) {
    const baseUrl = '#customers';
    const queryString = this.buildQueryString(stateObj);
    history.replaceState(stateObj, 'customers', baseUrl + '?' + queryString);
  }

  private updateListSettings() {
    this.getCustomers();
  }

  private getCustomers() {
    this.filters.top = this.pageSize;
    this.filters.skip = (this.currentPage - 1) * this.pageSize;

    this.customerService
      .getList(this.filters)
      .then((res) => {
        this.totalItems = res.headers.get('x-total-count');
        return res.text().then((responseText) => {
          this.tableData = JSON.parse(responseText);
          this.ready = true;
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.router.navigateToRoute('customer-detail', { Id: id });
  }

  private setSearchFocus(event) {
    if (event.detail) {
      this.searchInputHasFocus = event.detail.value;
    }
    if (!this.searchInputHasFocus) {
      this.filters.searchText = null;
      this.getCustomers();
    }
  }

  private getFilterkey() {
    return this.CUSTOMER_FILTERS_KEY;
  }

  private saveFilters() {
    localStorage.setItem(this.getFilterkey(), JSON.stringify(this.filters));
  }

  private doSaveStateAndUpdateCustomers() {
    this.saveFilters();
    this.getCustomers();
  }
}
