import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Coupling } from 'models/Connections';
import { CouplingService } from 'services';

@autoinject
export class CouplingNew {
  private coupling: Coupling = new Coupling();

  constructor(
    private couplingService: CouplingService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  protected async createCoupling() {
    try {
      const coupling = await this.couplingService.post(this.coupling);
      this.toastService.showSuccess('general.created');
      this.eventAggregator.publish('coupling-list-reset', 1);
      this.router.navigateToRoute('coupling-detail', { Id: coupling.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
