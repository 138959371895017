import { ToastService } from './../../services/toast-service';
import { AuthorizeStep } from './../../authorizeStep';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import tippy from 'tippy.js'

@autoinject
export class CustomerDetail {
  private customer: Models.Customer;

  private params;
  private isAdmin;

  constructor(    
    private router: Router,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private toastService: ToastService
  ) { }

  private activate(params) {
    this.getCustomer(params.Id);        
    this.isAdmin =  AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1;
  }

  private getCustomer(Id) {
      this.customerService
          .get(Id)
          .then((customer) => {
              this.customer = customer; 
              
              setTimeout(() => {          
                tippy('.tippy', {
                  content: (reference) => {            
                    const title = reference.getAttribute('title');
                    reference.removeAttribute('title');
                    return title;
                  }
                });
              });
          })
          .catch((err) => this.errorService.handleError(err));
  }
  
  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'customer-info',
        moduleId: PLATFORM.moduleName('./customer-info'),
        tabindex: 0,
        entityShortName: 'customer'
      },
      {
        route: ['nets'],
        name: 'customer-net-list',
        moduleId: PLATFORM.moduleName('./customer-net-list'),
        tabindex: 1,
        entityShortName: 'customer'
      },
      {
        route: ['special-products'],
        name: 'customer-special-product-list',
        moduleId: PLATFORM.moduleName('./customer-special-product-list'),
        tabindex: 2,
        entityShortName: 'customer'
      },
      {
        route: ['orders'],
        name: 'customer-order-list',
        moduleId: PLATFORM.moduleName('./customer-order-list'),
        tabindex: 3,
        entityShortName: 'customer'
      },
      {
        route: ['offers'],
        name: 'customer-offer-list',
        moduleId: PLATFORM.moduleName('./customer-offer-list'),
        tabindex: 4,
        entityShortName: 'customer'
      },
      {
        route: ['services'],
        name: 'customer-service-list',
        moduleId: PLATFORM.moduleName('./customer-service-list'),
        tabindex: 5,
        entityShortName: 'customer'
      }
    ]);
  }

  
}
