import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, containerless } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { ContactService } from 'services/contact-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class ContactFormInlineNew {
  @bindable private existingContacts: Array<Models.Contact> = [];
  @bindable private customerId: number;
  private contact: Models.Contact = new Models.Contact();
  protected alreadyExistsMessage: string;

  constructor(
    private contactService: ContactService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private validationController: ValidationController,
    private validator: Validator,
    private toastService: ToastService
  ) {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email')
      .email()
      .withMessage(this.i18n.tr('general.incorrectEmailFormat'))
      .on(this.contact);
  }

  private createContact() {
    this.contact.CustomerId = this.customerId;
    this.contactService
      .post(this.contact)
      .then(() => {
        this.eventAggregator.publish('contactListReset', 1);
        this.eventAggregator.publish('contactFormNewClose', 1);
        this.toastService.showSuccess('contact.created');
        this.contact = new Models.Contact();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async validateBeforeSave() {
    if (this.existingContacts?.length > 0) {
      const existingContact = this.existingContacts.some(
        (c) => c.Email?.toLowerCase() === this.contact.Email?.toLowerCase()
      );
      if (existingContact) {
        this.alreadyExistsMessage = this.i18n.tr('validation.ValueAlreadyExists', {
          value: this.i18n.tr('general.contact'),
        });
        return;
      }
    }

    await this.validator.validateObject(this.contact).then(async (result) => {
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });
      if (errors.length > 0) {
        await this.validationController.validate();
      } else {
        this.createContact();
      }
    });
  }

  protected cancel() {
    this.eventAggregator.publish('contactFormNewClose', 1);
  }
}
