import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator'; 
import { CustomerNetTypeService } from 'services/customer-net-type-service';

@autoinject
export class CustomerNetTypeDetail {
  private customernettype: Models.CustomerNetType;

  constructor(
    private customerNetTypeService: CustomerNetTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.customerNetTypeService
      .get(params.Id)
      .then(netType => {
        this.customernettype = netType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateCustomerNetType() {
    this.customerNetTypeService
      .put(this.customernettype, this.customernettype.Id)
      .then(res => {
        this.eventAggregator.publish('customerNetTypeListReset', 1);
        this.toastService.showSuccess('customernettype.updated');
        this.router.navigateToRoute('customer-net-type-list');
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteNetType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.customerNetTypeService
          .delete(this.customernettype.Id)
          .then(res => {
            this.eventAggregator.publish('customerNetTypeListReset', 1);
            this.toastService.showSuccess('customernettype.deleted');
            this.router.navigateToRoute('customer-net-type-list');
          })
          .catch(err => this.errorService.handleError(err));
        }
      );   
  }
  
  gotoParentView(){
    this.eventAggregator.publish('customerNetTypeListReset', 1);
    this.router.navigateToRoute('customer-net-type-list');
  }
}
