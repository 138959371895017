import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { CollectorService } from 'services/collector-service';
import { ErrorService } from 'services/error-service';

@autoinject
export class CollectorList {
  private tableData: Array<Models.Collector>;
  private activeTableRow: number;
  private ready: boolean;

  constructor(
    private collectorService: CollectorService,
    private errorService: ErrorService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private attached(params) {
    this.eventAggregator.subscribe('collectorListReset', (value) => {
      this.activeTableRow = null;
      return this.updateView();
    });
    this.updateView();
  }

  private updateView() {
    this.collectorService
      .getAll()
      .then((res) => {
        this.ready = true;
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('collector-detail', { Id: id });
  }
}
