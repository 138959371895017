import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { CuttingStrapTypeService } from "services/cutting-strap-type-service";

@autoinject
export class CuttingStrapTypeNew {
  private cuttingstraptype: Models.CuttingStrapType = new Models.CuttingStrapType();

  constructor(
    private cuttingStrapTypeService: CuttingStrapTypeService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createCuttingStrapType() {
    this.cuttingStrapTypeService
      .post(this.cuttingstraptype)
      .then(res => {
        this.eventAggregator.publish("cuttingStrapTypeListReset", 1);
        this.toastService.showSuccess("cuttingstraptype.created");
        this.router.navigateToRoute("cutting-strap-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
