import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class CustomerMerge {

  private customer: Models.Customer;
  
  private mergedata: any;
  private isMerging: boolean = false;

  constructor(
    private customerService: CustomerService,
    private errorService: ErrorService,
    private i18n: I18N,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private validationController: ValidationController,
    private validator: Validator,
    private deleteDialogService: DeleteDialogService,
    private dialogService: DialogService,
    private prompt: Prompt,
    private utility: Utility
  ) {

  }

  private activate(params) {
    this.customerService
      .get(params.Id)
      .then((customer) => {
        this.customer = customer;

        this.mergedata = {
          OldCustomerId: this.customer.Id,
          NewCustomerId: null
        };
      })
      .catch((err) => this.errorService.handleError(err));
  }
  
  private mergeCustomers() {
    if (!this.mergedata.NewCustomerId) {
      this.toastService.showError('customer.mergenocustomerselected');
      return;
    }

    if (this.mergedata.NewCustomerId == this.mergedata.OldCustomerId) {
      this.toastService.showError('customer.mergesamecustomerselected');
      return;
    }

    return this.dialogService.open({
      viewModel: Prompt,
      model: {
        header: 'dialog.customermergeconfirm',
        message: 'dialog.customermergeconfirmtext',
        actions: {
          delete: { enabled: false },
          save: { enabled: false },
          cancel: { enabled: true, t: 'dialog.cancel'  },
          dontsave: { enabled: false },
          continue: { enabled: true, t: 'customer.merge'  }
      }
    }
    }).whenClosed((response) => {
      if (response.wasCancelled) {
        return false;
      } else {
        const result = response.output;
        if (result === 'continue') {   
          this.isMerging = true;       
          this.customerService.mergeCustomers(this.mergedata)
            .then(res => {
              this.isMerging = false;
              this.toastService.show('info', 'customer.customermerged');
              this.router.navigateToRoute('customer-detail', { Id: this.mergedata.NewCustomerId });
            })
            .catch(err => {
              this.isMerging = false;
              this.errorService.handleError(err)
            });
          return false;
        } else {
          return true;
        }
      }
    });    
  }
}
