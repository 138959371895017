import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CustomerNetTypeService } from 'services/customer-net-type-service';

@autoinject
export class CustomerNetTypeNew {
  private customernettype: Models.CustomerNetType = new Models.CustomerNetType();

  constructor(
    private customerNetTypeService: CustomerNetTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createCustomerNetType() {
    this.customerNetTypeService
      .post(this.customernettype)
      .then(res => {
        this.eventAggregator.publish('customerNetTypeListReset', 1);
        this.toastService.showSuccess('customernettype.created');
        this.router.navigateToRoute('customer-net-type-detail', { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
