import { AuthorizeStep } from './../../authorizeStep';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('customer-edit');

@autoinject
export class CustomerEdit {
  private customer: Models.Customer;
  private originalObject: Models.Customer;

  constructor(
    private customerService: CustomerService,
    private errorService: ErrorService,
    private i18n: I18N,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private validationController: ValidationController,
    private deleteDialogService: DeleteDialogService,
    private dialogService: DialogService,
    private utility: Utility,
    protected prompt: Prompt,
    protected validator: Validator
  ) {}

  activate(params: { Id: number }) {
    this.customerService
      .get(`${params.Id}?$expand=DocumentAttachments`)
      .then((customer) => {
        this.customer = customer;
        this.originalObject = JSON.parse(JSON.stringify(this.customer));

        ValidationRules.ensure('Name')
          .required()
          .withMessage(this.i18n.tr('general.requiredField'))
          .ensure('Email')
          .required()
          .withMessage(this.i18n.tr('general.requiredField'))
          .ensure('Email')
          .email()
          .withMessage(this.i18n.tr('general.incorrectEmailFormat'))
          .on(this.customer);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateCustomer() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.customerService
          .put(this.customer, this.customer.Id)
          .then((res) => {
            this.originalObject = null;
            this.eventAggregator.publish('customerListReset', 1);
            this.toastService.showSuccess('customer.updated');
            this.router.navigateToRoute('customer-detail', { Id: this.customer.Id });
          })
          .catch((err) => this.errorService.handleError(err));
      } else {
        this.toastService.showError('general.checkValidationErrorsOnSave');
      }
    });
  }

  private deleteCustomer() {
    this.customerService
      .checkCanDelete(this.customer.Id)
      .then((checkDelete) => {
        let dialogTextKey = 'dialog.deleteConfirmation';

        if (checkDelete.onlyIfAdmin && AuthorizeStep.auth.roles.indexOf('Administrator') === -1) {
          this.toastService.showError('customer.cannotDeleteCustomerWithNetsNeedAdminPermission');
          return;
        }

        if (checkDelete.hasRelatedData) {
          dialogTextKey = 'customer.confirmDeleteWithRelatedData';
        }

        logger.debug('dialogTextKey', dialogTextKey);

        this.deleteDialogService.confirmBeforeDelete(
          () => {
            this.customerService
              .delete(this.customer.Id)
              .then(() => {
                this.originalObject = null;
                this.eventAggregator.publish('customerListReset', 1);
                this.toastService.showSuccess('customer.deleted');
                this.router.navigateToRoute('customer-list');
              })
              .catch((err) => this.errorService.handleError(err));
          },
          'dialog.deleteHeading',
          dialogTextKey
        );
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected gotoParentView() {
    this.eventAggregator.publish('customerListReset', 1);
    this.router.navigateToRoute('customer-list');
  }

  protected canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.customer, this.originalObject, true)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              this.updateCustomer();
              return false;
            } else {
              return true;
            }
          }
        });
    } else {
      return true;
    }
  }
}
