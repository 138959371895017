import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { UserModels } from 'models/UserModels';
import { ErrorService } from 'services/error-service';
import { UserService } from 'services/user-service';

@autoinject
export class SalesDashboard {
  private params: any;
  private offerPageSize: number;
  private orderPageSize: number;
  private userData: UserModels.User;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private userService: UserService
  ) {  }

  private activate(params) {
    this.params = params;
    this.getUserData();
  }

  private getUserData() {
    this.userService.getCurrentUser()
      .then((res) => {
        this.userData = res;
      });
  }

  public newOffer() {    
    this.eventAggregator.publish('CreateOfferDialogOpen', true);    
  }
}
