import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

const logger: Logger = LogManager.getLogger("customer-new");

@autoinject
export class CustomerNew {
  private customer: Models.Customer = new Models.Customer();
  private originalObject;
  private saving: boolean = false;

  constructor(
    private customerService: CustomerService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private validationController: ValidationController,
    private validator: Validator

  ) {

    ValidationRules
      .ensure('Name').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('Email').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('Email').email().withMessage(i18n.tr('general.incorrectEmailFormat'))
      .on(this.customer);
  }

  private activate(params) {
    this.customer.Country = 'Norway';
    this.customer.IndustryId = 1;
    this.originalObject = JSON.parse(JSON.stringify(this.customer));
  }

  private createCustomer() {
    this.saving = true;

    this.customerService.validateCustomer(this.customer)
    .then((valid) => {

      // check if customer already exists
      if (valid.OrgNoExists) {
        this.saving = false;
        return this.toastService.showError('customer.alreadyExistsOrgNo');
      } else if (valid.NameExists) {
        this.saving = false;
        return this.toastService.showError('customer.alreadyExistsName');
      }

      this.validationController.validate()
      .then((result) => {
        logger.debug('validationresult ', result);
        if (result.valid) {
          this.customerService
            .post(this.customer)
            .then((res) => {
              this.originalObject = null;
              this.eventAggregator.publish('customerListReset', 1);
              this.toastService.showSuccess('customer.created');
              this.router.navigateToRoute('customer-detail', { Id: res.Id });
              this.saving = false;
            })
            .catch((err) => {
              logger.debug('err', err);
              this.saving = false;
              this.errorService.handleError(err);
            });
        } else {
          this.toastService.showError('general.checkValidationErrorsOnSave');
          this.saving = false;
        }
      });
    })
    .catch((err) => {
      this.errorService.handleError(err);
      this.saving = false;      
    });
  }

  private canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    if (!this.utility.areEqual(this.customer, this.originalObject, true)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            this.createCustomer();
            return false;
          } else {
            return true;
          }
        }
      });
    } else {
      return true;
    }
  }

}
