import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CouplingService } from 'services';
import { Coupling } from 'models/Connections';

@autoinject
export class CouplingDetail {
  private coupling: Coupling;

  constructor(
    private couplingService: CouplingService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  protected activate(params: { Id: number }) {
    void this.getCoupling(params.Id);
  }

  protected async getCoupling(id: number) {
    try {
      this.coupling = await this.couplingService.get(id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async updateCoupling() {
    try {
      this.coupling = await this.couplingService.put(this.coupling, this.coupling.Id);
      this.eventAggregator.publish('coupling-list-reset', 1);
      this.toastService.showSuccess('general.saved');
      this.router.navigateToRoute('coupling-list');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected deleteCoupling() {
    void this.deleteDialogService.confirmBeforeDelete(async () => {
      try {
        this.coupling = await this.couplingService.delete(this.coupling.Id);
        this.eventAggregator.publish('coupling-list-reset', 1);
        this.toastService.showSuccess('coupling.deleted');
        this.router.navigateToRoute('coupling-list');
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }

  gotoParentView() {
    this.eventAggregator.publish('coupling-list-reset', 1);
    this.router.navigateToRoute('coupling-list');
  }
}
