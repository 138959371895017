import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 
import { CuttingStrapTypeService } from "services/cutting-strap-type-service";

@autoinject
export class cuttingStrapTypeDetail {
  private cuttingstraptype: Models.CuttingStrapType;

  constructor(
    private cuttingStrapTypeService: CuttingStrapTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.cuttingStrapTypeService
      .get(params.Id)
      .then(cuttingStrapType => {
        this.cuttingstraptype = cuttingStrapType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateCuttingStrapType() {
    this.cuttingStrapTypeService
      .put(this.cuttingstraptype, this.cuttingstraptype.Id)
      .then(res => {
        this.eventAggregator.publish("cuttingStrapTypeListReset", 1);
        this.toastService.showSuccess("cuttingstraptype.updated");
        this.router.navigateToRoute("cutting-strap-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteCuttingStrapType() {
    
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.cuttingStrapTypeService
          .delete(this.cuttingstraptype.Id)
          .then(res => {
            this.eventAggregator.publish("cuttingStrapTypeListReset", 1);
            this.toastService.showSuccess("cuttingstraptype.deleted");
            this.router.navigateToRoute("cutting-strap-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );  
  }
  gotoParentView(){
    this.eventAggregator.publish("cuttingStrapTypeListReset", 1);
    this.router.navigateToRoute("cutting-strap-type-list");
  }
}
