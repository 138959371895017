import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { CollectorService } from "services/collector-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class CollectorNew {
  private collector: Models.Collector = new Models.Collector();

  constructor(
    private collectorService: CollectorService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createCollector() {
    this.collectorService
      .post(this.collector)
      .then(res => {
        this.eventAggregator.publish("collectorListReset", 1);
        this.toastService.showSuccess("collector.created");
        this.router.navigateToRoute("collector-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
