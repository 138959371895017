import { autoinject } from 'aurelia-dependency-injection';
import { ErrorService } from 'services/error-service';
import { ServiceStationService } from 'services/service-station-service';
import { UserService } from 'services/user-service';

@autoinject()
export class CustomerList {
  private params: any;

  userIndustryId: number | undefined;
  doneFetchingIndustryId: boolean = false;

  constructor(
    private serviceStationService: ServiceStationService,
    private userService: UserService,
    private errorService: ErrorService
  ) {
    void this.setCurrentUserIndustryId();
  }

  private activate(params) {
    this.params = params;
  }

  /**
   * Tries to get and set the current users industry.
   * It will only be set if the current user has a service station.
   */
  private async setCurrentUserIndustryId() {
    try {
      const currentUser = await this.userService.getCurrentUser();
      if (!!currentUser?.ServiceStationId) {
        const serviceStation = await this.serviceStationService.get(
          currentUser.ServiceStationId
        );

        if (!!serviceStation?.IndustryId) {
          this.userIndustryId = serviceStation.IndustryId;
        }
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.doneFetchingIndustryId = true;
  }
}
